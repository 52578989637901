import * as CookieConsent from 'vanilla-cookieconsent';
import $ from "jquery";

// https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions

function logConsent() {
  // console.log('log consent', CookieConsent.getCookie(), CookieConsent.getUserPreferences());

  const data = {
    cookie: CookieConsent.getCookie(),
    preferences: CookieConsent.getUserPreferences(),
  };

  // console.info('data', JSON.stringify(data));

  $.ajax({
    type: "POST",
    url: "/api/logconsent",
    data: JSON.stringify(data),
    contentType: "application/json",
    success: function (data) {
      console.log('log consent success');
    },
    error: function (data) {
      console.log('log consent error');
    },
  });
}

export default function initCookieConsent() {
  function init () {
    CookieConsent.run({
      // root: 'body',
      // autoShow: true,
      // disablePageInteraction: true,
      // hideFromBots: true,
      // mode: 'opt-in',
      // revision: 0,

      // cookie: {
      //   name: 'cc_cookie',
      //   // domain: location.hostname,
      //   // path: '/',
      //   // sameSite: "Lax",
      //   // expiresAfterDays: 365,
      // },

      guiOptions: {
        consentModal: {
          layout: 'bar',
          position: 'bottom',
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: false
        }
      },

      onFirstConsent: ({ cookie }) => {
        // console.log('onFirstConsent fired', cookie);
        logConsent();
      },

      onConsent: ({ cookie }) => {
        // console.log('onConsent fired!', cookie)

        if (cookie.categories.includes('analytics')) {
          // console.info('allowing analytics');
          gtag("consent", "update", {
            analytics_storage: "granted",
          });

          if (typeof fbevents == "function") {
            // console.info("calling fbevents");
            fbevents();
          }
        }

        if (cookie.categories.includes('marketing')) {
          // console.info('allowing marketing');
          gtag("consent", "update", {
            ad_storage: "granted",
          });
        }
      },

      onChange: ({ cookie, changedCategories, changedServices }) => {
        console.log('onChange fired!', cookie, changedCategories, changedServices);

        if (changedCategories.includes('analytics')) {
          // console.info('changing analytics');
          gtag("consent", "update", {
            analytics_storage: cookie.categories.includes('analytics') ? "granted" : "denied",
          });
        }

        if (changedCategories.includes('marketing')) {
          // console.info('changing marketing');
          gtag("consent", "update", {
            ad_storage: cookie.categories.includes('ads') ? "granted" : "denied",
          });
        }

        logConsent();
      },

      // onModalReady: ({ modalName }) => {
      //   console.log('ready:', modalName);
      // },

      // onModalShow: ({ modalName }) => {
      //   console.log('visible:', modalName);
      // },

      // onModalHide: ({ modalName }) => {
      //   console.log('hidden:', modalName);
      // },

      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functionality: {
          autoClear: {
            cookies: [
              {
                name: /^__zlcmid/,
              }
            ]
          },
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^_ga/,   // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid',   // string: exact cookie name
              }
            ]
          },
          // services: {
          //   ga: {
          //     label: 'Google Analytics',
          //     onAccept: () => { },
          //     onReject: () => { }
          //   },
          //   youtube: {
          //     label: 'Youtube Embed',
          //     onAccept: () => { },
          //     onReject: () => { }
          //   },
          // }
        },
        ads: {
          autoClear: {
            cookies: [
              {
                name: '_gcl_au',
              }
            ]
          },
        }
      },

      language: {
        default: 'nl',
        translations: {
          nl: {
            consentModal: {
              title: 'Golftime maakt gebruik van cookies.',
              description: 'Door het gebruik van cookies kunnen wij meten hoe onze site wordt gebruikt, hoe deze nog verder kan worden verbeterd en om de inhoud van online advertentie uitingen te personaliseren.',
              // revisionMessage: "Hi, we've made some changes to our cookie policy since the last time you visited!",
              acceptAllBtn: 'Alles aanvaarden',
              acceptNecessaryBtn: 'Alles weigeren',
              showPreferencesBtn: 'Instellen',
              // closeIconLabel: 'Reject all and close modal',
              footer: `
              <a href="/golftime/privacy-policy/" target="_blank">Lees hier alles over ons cookiebeleid.</a>
            `,
            },
            preferencesModal: {
              title: 'Beheer jouw cookievoorkeuren',
              acceptAllBtn: 'Alles aanvaarden',
              acceptNecessaryBtn: 'Alles weigeren',
              savePreferencesBtn: 'Voorkeuren opslaan',
              closeIconLabel: 'Sluiten',
              serviceCounterLabel: 'dienst|diensten',
              sections: [
                {
                  title: 'Jouw privacy instellingen',
                  description: `
                  Hieronder kan je kiezen voor welke doeleinden je cookies wil toelaten
                  op de Golftime website. We geven ook meer uitleg over het doel van
                  iedere soort cookies. Meer informatie kan je steeds terugvinden
                  in ons <a href="/golftime/privacy-policy/" target="_blank">cookiebeleid</a>.`,
                },
                {
                  title: 'Noodzakelijk',
                  description: `
                  Noodzakelijke cookies helpen een website bruikbaarder te maken, door basisfuncties
                  als paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te
                  maken. Zonder deze cookies kan de website niet naar behoren werken.
                `,
                  linkedCategory: 'necessary',
                  cookieTable: {
                    caption: 'Cookie tabel',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domein',
                      desc: 'Omschrijving'
                    },
                    body: [
                      {
                        name: 'PHPSESSID',
                        domain: location.hostname,
                        desc: `
                        Dit is een identificatie voor algemene doeleinden die wordt gebruikt
                        om variabelen van gebruikerssessies bij te houden.
                      `,
                      },
                      {
                        name: '_GRECAPTCHA ',
                        domain: 'google.com',
                        desc: `
                        Google reCAPTCHA stelt deze noodzakelijke cookie in
                        wanneer deze wordt uitgevoerd met het doel de risicoanalyse uit te
                        voeren.
                      `,
                      },
                      {
                        name: '__cfruid',
                        domain: '.golftime.zendesk.com',
                        desc: `
                        Cloudflare stelt deze cookie in om vertrouwd webverkeer te identificeren.
                      `,
                      }
                    ]
                  }
                },
                {
                  title: 'Functioneel',
                  description: `
                  Functionele cookies zorgen ervoor dat een website informatie kan onthouden die van
                  invloed is op het gedrag en de vormgeving van de website, zoals de taal van je
                  voorkeur of de regio waar je woont.
                `,
                  linkedCategory: 'functionality',
                  cookieTable: {
                    caption: 'Cookie tabel',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domein',
                      desc: 'Omschrijving'
                    },
                    body: [
                      {
                        name: '__zlcmid',
                        domain: location.hostname,
                        desc: `
                        De livechatwidget stelt de cookies in om de Zopim Live Chat-ID op te
                        slaan die wordt gebruikt om een ​​apparaat tijdens bezoeken te
                        identificeren.
                      `,
                      },
                    ]
                  }
                },
                {
                  title: 'Statistieken',
                  description: `
                  Een goede website is nooit af. Met cookies voor statistieken analyseren we hoe
                  bezoekers onze website gebruiken. Zo ontdekken we wat goed werkt én wat wij
                  nog beter moeten doen.
                `,
                  linkedCategory: 'analytics',
                  cookieTable: {
                    caption: 'Cookie tabel',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domein',
                      desc: 'Omschrijving'
                    },
                    body: [
                      {
                        name: '_ga',
                        domain: location.hostname,
                        desc: `
                        Deze cookie is gekoppeld aan Google Universal Analytics. De cookie wordt
                        gebruikt om unieke gebruikers te onderscheiden door een willekeurig
                        gegenereerd nummer toe te wijzen als klantidentificatie. Het wordt
                        opgenomen in elk paginaverzoek op een site en wordt gebruikt om
                        bezoekers-, sessie- en campagnegegevens te berekenen voor de
                        analyserapporten van de site.
                      `,
                      },
                      {
                        name: '_ga_*',
                        domain: location.hostname,
                        desc: `
                        Deze cookie wordt door Google Analytics gebruikt om de sessiestatus
                        vast te houden.
                      `,
                      },
                      {
                        name: '_gid',
                        domain: location.hostname,
                        desc: `
                        Deze cookie wordt geplaatst door Google Analytics. Het slaat een unieke
                        waarde op voor elke bezochte pagina en werkt deze bij, en wordt gebruikt
                        om paginaweergaven te tellen en bij te houden.
                      `,
                      }
                    ]
                  }
                },
                {
                  title: 'Marketing',
                  description: `
                  Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende
                  websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en
                  relevant zijn voor de individuele gebruiker. Deze advertenties worden zo
                  waardevoller voor uitgevers en externe adverteerders.
                `,
                  linkedCategory: 'ads',
                  cookieTable: {
                    caption: 'Cookie tabel',
                    headers: {
                      name: 'Cookie',
                      domain: 'Domein',
                      desc: 'Omschrijving'
                    },
                    body: [
                      {
                        name: '_gcl_au ',
                        domain: location.hostname,
                        desc: `
                        Gebruikt door Google AdSense om te experimenteren met advertentie-efficiëntie
                        op websites die hun services gebruiken.
                      `,
                      }
                    ]
                  }
                },
                // {
                //   title: 'More information',
                //   description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                // }
              ]
            }
          }
        }
      }
    });

    // change cookie consent button
    $('#cc-change-consent').on('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      CookieConsent.showPreferences();
    });

  }

  init();
}
